var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap bg_white", staticStyle: { width: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("div", {
          staticClass: "circle_icon check",
          staticStyle: { margin: "30px auto 0" },
        }),
        _c(
          "p",
          {
            staticClass: "msg text_center color_blue font_16 font_medium mt15",
          },
          [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P060.001")) + " ")]
        ),
        _c("p", { staticClass: "msg text_center mt15" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P060.002")) + " "),
        ]),
        _c("div", { staticClass: "text_center mt30" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.insertInvoiceReq()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.003")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.004")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }